import C from 'consistencss';
import {wFonts} from '../styles/typografy';
import {Text, TouchableOpacity} from 'react-native';
import React from 'react';
import {bordColor, textColor} from '../styles/color';
import {DgIcon} from '../⚛️ atoms/DgIcon';
import {pillWrapper} from '../styles/ui';
import {useStores} from '../store/UserProvider';

export default ({name, onPress, number, logo, iconName, color, selected}) => {
  const {shadow, colors} = useStores();
  const currColor = color || colors.primary;
  /*const currColorBg = colorBg || colors.primaryLight;*/
  /**Todo add chroma for more contrast check?*/
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        pillWrapper,
        selected && shadow,
        !logo && C.px1,
        C.radius2,
        C.p1,
        bordColor(currColor),
      ]}>
      <DgIcon color={currColor} name={name} iconName={iconName} />
      <Text style={[textColor(currColor), wFonts.body1]}>
        {name} {number && number > 0 && `(${number})`}
      </Text>
    </TouchableOpacity>
  );
};
