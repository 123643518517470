import {FlatList, Text, View} from 'react-native';
import React from 'react';
import C from 'consistencss';
import {textColor} from '../styles/color';
import {SideCard} from '../🧫 molecules/SideCard';
import LinearGradient from 'react-native-linear-gradient';
import {BASE_PIXEL, deviceWidth, isSmall, respL} from '../styles/sizes';
import {useStores} from '../store/UserProvider';

import {useObserver} from 'mobx-react';
import {wFonts} from '../styles/typografy';
import {trans} from '../utils/translations';
import {WfCard} from '../comp/WfCard';
import {JobDetailsModal} from '../screens/JobDetailsModal';

export const Timeline = () => {
  const store = useStores();

  return useObserver(() => (
    <View style={[C.m4, C.itemsCenter]}>
      {/**Highlights*/}
      {store.detailsModal.show && (
        <JobDetailsModal modal={store.detailsModal} />
      )}
      <FlatList
        keyExtractor={({name}) => name}
        data={store.currentHighlights}
        numColumns={isSmall ? 1 : respL ? 3 : 2}
        renderItem={({item}) => {
          return <WfCard {...item} collapsed={false} />;
        }}
      />

      {/** Actual timeline filtered list*/}
      <Text
        style={[
          wFonts.mainTitle,
          C.my8,
          C.pt4,
          textColor(store.colors.primary),
        ]}>
        {trans('⌛️ Timeline')}
      </Text>
      <FlatList
        data={store.matchingList}
        contentContainerStyle={[C.selfCenter]}
        keyExtractor={({name}) => name}
        scrollEnabled={false}
        renderItem={({item, index}) => (
          <SideCard {...item} left={index % 2 === 0} />
        )}
        ListFooterComponent={() => (
          <LinearGradient
            colors={store.mainGrad}
            useAngle
            angle={-90}
            style={[
              C.radius10,
              {left: deviceWidth * (isSmall ? 0.45 : 0.4) - BASE_PIXEL},
              C.top20,
              C.w2,
              C.h10,
            ]}
          />
        )}
      />
    </View>
  ));
};
