import C from 'consistencss';
import React, {useState} from 'react';
import {FlatList, Image, Text, TouchableOpacity, View} from 'react-native';
import {LandingCard, VCheck} from '../\uD83E\uDDEB molecules/Cards';
import {SearchBar} from '../\uD83E\uDDECorganisms/SearchBar';
import {
  CircWrapper,
  ControlledScroll,
  Footer,
  GroupedBubbles,
  Highlight,
  RespRow,
  SideAbsoluteContainer,
  SideContainer,
  VButton,
  Wrapper,
} from '../comp/Wrapper';
/*import {
  wFonts,
  isSmall,
  leftRadius,
  onTop,
  percHeight,
  percWidth,
  respL,
  respWrap,
  textColor,
  topRadius,
  vColors,
  vImgs,
  vSizes,
} from '../design/vStyles';*/
import {vColors} from '../styles/color';
import {
  isSmall,
  onTop,
  percWidth,
  respL,
  respWrap,
  vSizes,
} from '../styles/sizes';
import {allAnimals, landingData, mockSpecialists} from '../mock/mock';
import {trans} from '../utils/translations';
import {wFonts} from '../styles/typografy';
import {vImgs} from '../styles/icons';
import {guilleMock, keywords} from '../mock/guille';
import Pill from '../🧫 molecules/Pill';
import {Header} from '../🧬organisms/Header';
import {useObserver} from 'mobx-react';
import {useNavigation} from '@react-navigation/core';
import {useStores} from '../store/UserProvider';

/*export const SoonModal = ({text, textCol = vColors.primaryBlue}) => (
const store = useStores();
<VModal modal={store.modal} visible contentStyle={[C.p0, C.top_6]}>
    <View style={[!isSmall && C.row, C.flex]}>
      <View style={[C.flex]}>
        <Image
          source={vImgs.mockupFind}
          resizeMode={'contain'}
          style={[
            vSizes.cardBig,

            isSmall
              ? [
                  topRadius(8),
                  C.top_1,
                  C.selfLeft,
                  percWidth(1),
                  percHeight(0.4),
                ]
              : [
                  C.flex,
                  C.selfLeft,
                  C.mx10,
                  C.p4,
                  leftRadius(8),
                  percHeight(0.5),
                ],
            C.mt_10,
          ]}
        />
      </View>
      <View style={[C.flex, C.justifyCenter]}>
        <View
          style={[C.selfCenter, isSmall ? C.mx10 : C.mr20, isSmall && C.mt10]}>
          <Text style={[wFonts.mainTitle, textColor(textCol)]}>
            {trans('🚀 ¡Muy pronto!')}
          </Text>
          <Text style={[wFonts.body1, C.my6]}>{trans(text)}</Text>
          <Text style={[wFonts.textBold, C.mb6]}>
            {trans('Déjanos tu correo y te avisaremos cuando esté disponible.')}
          </Text>
          <WaitingListMail />
        </View>
      </View>
    </View>
  </VModal>
);*/

export const AppsofactoLanding = ({user = guilleMock, users, setCurrent}) => {
  const {navigate} = useNavigation();
  const [showMore, setShowMore] = useState(false);
  const store = useStores();

  const openModal = () => {};
  return useObserver(() => (
    <Wrapper
      bgGradient={store.mainGrad}
      HeaderChild={
        <View style={[C.itemsCenter, C.pb20]}>
          <Header />
          <SideAbsoluteContainer
            img={vImgs.woman}
            isAbs
            imgRatio={isSmall ? 0.5 : 0.4}
            smallAbs
            imgStyle={[
              isSmall && C.top_20,
              /*isSmall ? C.bottom_48 : C.bottom_58,*/ C.right_110,
            ]}>
            <Text style={[wFonts.heroTitle, C.textWhite, C.maxw180]}>
              {trans('Resuelve cualquier caso en cuestión de minutos')}
            </Text>

            {/**SearchBar*/}
            <SearchBar
              onPressFilter={openModal}
              tabs={landingData.cards}
              text={'rhdhore '}
              onPress={openModal}
              placeholder={'Centros veterinarios o especialistas'}
            />

            {/**Filters*/}
            <FlatList
              numColumns={isSmall ? 3 : 4}
              keyExtractor={({name}) => name}
              contentContainerStyle={[C.selfStart, onTop(), C.selfStart, C.my8]}
              data={Object.values(keywords).splice(
                0,
                showMore ? 80 : isSmall ? 9 : 8,
              )}
              renderItem={({item}) => (
                <Pill key={item.name} transp {...item} onPress={openModal} />
              )}
            />
            <Text
              style={[C.textWhite, wFonts.textBold]}
              onPress={() => setShowMore(!showMore)}>
              {!showMore ? '+ Ver más' : '- Ver menos'}
            </Text>
          </SideAbsoluteContainer>
        </View>
      }>
      {/**Highlights*/}
      <RespRow style={[C.mb10, C.mt20]}>
        {landingData.highlights.map(props => (
          <Highlight {...props} />
        ))}
      </RespRow>

      {/**Clinicas de cofnianza*/}
      <SideContainer
        colBg={vColors.whiteTwo}
        fullImg
        imgSize={respL ? vSizes.cardHero : vSizes.cardHuge}
        imgRatio={0.5}
        imgStyle={
          isSmall
            ? [C.bottom_8]
            : [C.absolute, respL ? C.bottom_44 : C.bottom_24]
        }
        img={vImgs.vetManGroupedDesktop}>
        <View style={[C.row, C.itemsCenter, C.mb4]}>
          <Image
            source={vImgs.tickVerification}
            resizeMode={'cover'}
            style={[vSizes.med, C.mr4]}
          />
          <Text style={wFonts.title1}>⭐️⭐⭐️⭐⭐</Text>
        </View>

        <Text style={wFonts.mainTitle}>
          {'Profesionales verificados y clínicas de confianza'}
        </Text>
        <Text style={[C.my5, wFonts.body1]}>
          {
            '¡Los mejores profesionales en tu bolsillo! Encuentra veterinarios verificados por nuestro equipo y valorados por sus clientes. '
          }
        </Text>
        <GroupedBubbles length={5} list={mockSpecialists} />

        <VButton
          title={'¿Eres Veterinario?'}
          highlight
          /*onPress={() => navigate(signedIn.VetLanding.name)}*/
          colorful
          wrapStyle={[C.selfStart, C.my4]}
        />
      </SideContainer>

      {/**Calendar*/}
      <SideContainer imgLeft img={vImgs.SchedulePurple} imgRatio={0.4}>
        <Text style={wFonts.mainTitle}>
          {'Reserva cita con clínicas y especialistas al instante'}
        </Text>
        <Text style={[C.my5, wFonts.body1]}>
          {
            'Elige el especialista que mejor se adapte a las necesidades de tu mascota y reserva cita. ¡Rápido, fácil y sin ningún coste añadido!'
          }
        </Text>
        <VButton
          title={'Reservar cita'}
          highlight
          colorful
          onPress={openModal}
          wrapStyle={[C.selfStart, C.my4]}
        />
      </SideContainer>

      {/**Cards*/}
      <View style={[C.my12, respWrap]}>
        <Text style={[wFonts.mainTitle]}>
          {'En cualquier momento, en cualquier lugar'}
        </Text>
        <FlatList
          keyExtractor={({title}) => title}
          showsHorizontalScrollIndicator={false}
          horizontal
          contentContainerStyle={isSmall && [percWidth(), C.selfStart]}
          data={landingData.cards}
          renderItem={({item}) => <LandingCard {...item} />}
        />
      </View>

      {/**All Animals*/}
      <SideContainer imgLeft colBg={vColors.whiteTwo} img={vImgs.animalPhotos}>
        <Text style={[wFonts.mainTitle]}>{'Para todas las mascotas'}</Text>
        <Text style={[C.my5, wFonts.body1]}>
          {
            'En dgFolio, queremos ayudar a todas y cada una de vuestras mascotas. ¡Porque una mascota sana, es una mascota feliz!'
          }
        </Text>

        <ControlledScroll
          list={Object.values(allAnimals)}
          renderItem={({item, index, currItem}) => (
            <TouchableOpacity
              onPress={openModal}
              style={[C.selfStart, C.itemsCenter, C.mr5]}>
              <CircWrapper
                size={vSizes.med}
                bordCol={
                  index === currItem ? vColors.primaryBlue : vColors.whiteThree
                }>
                <Text
                  style={[
                    index === currItem ? vSizes.emojiLg : vSizes.emojiSm,
                  ]}>
                  {item.icon}
                </Text>
              </CircWrapper>
              <Text style={[wFonts.subtitle]}>{item.name}</Text>
            </TouchableOpacity>
          )}
        />
      </SideContainer>

      {/**Mockup*/}
      <SideContainer img={vImgs.mobileMock} imgStyle={[C.top0]}>
        <Text style={wFonts.mainTitle}>
          {'¿Eres veterinario o tienes una clínica?'}
        </Text>
        <Text style={[C.my5, wFonts.body1]}>
          {
            '¡Forma parte de la comunidad más grande de veterinarios y especialistas!'
          }
        </Text>
        {landingData.checks.map(item => (
          <VCheck
            checked
            checkCol={vColors.primaryGreen}
            text={item}
            title
            wrapStyle={[C.my3]}
          />
        ))}
        <View style={[C.row, C.itemsCenter, C.mt6]}>
          <VButton
            title={'¡Únete gratis!'}
            onPress={openModal}
            highlight
            colorful
            wrapStyle={[C.selfStart]}
          />
          <Text
            /*onPress={() => navigate(signedIn.VetLanding.name)}*/
            style={[wFonts.cta1, C.ml10]}>
            {trans('Infórmate')}
          </Text>
        </View>
      </SideContainer>

      {/*  {profile.soonModal.show && (
        <SoonModal
          text={
            '¡Ya queda menos para que tu y tus peludos podáis tener a los mejores profesionales veterinarios en vuestro bolsillo!'
          }
        />
      )}*/}
      <Footer />
    </Wrapper>
  ));
};
