import {ScrollView, View} from 'react-native';
import React, {useState} from 'react';
import {
  botRadius,
  isIOS,
  isSmall,
  onTop,
  percWidth,
  topRadius,
} from '../styles/sizes';
import C from 'consistencss';
import {useObserver} from 'mobx-react';
import Modal from 'modal-enhanced-react-native-web';
import {DgIcon} from '../⚛️ atoms/DgIcon';
import {useStores} from '../store/UserProvider';

export const DgModal = ({
  modal,
  visible = false,
  afterClose = () => {},
  isFull,
  icon,
  children,
  contentStyle,
  title,
}) => {
  const store = useStores();
  const [showModal, setShowModal] = useState(modal ? modal.show : visible);
  const closeModal = () => {
    if (modal) {
      modal.closeModal();
    }
    setShowModal(false);
    afterClose && afterClose();
  };
  //const showHeader = title || icon;

  return useObserver(() => (
    <Modal
      onDismiss={closeModal}
      onBackButtonPress={closeModal}
      isVisible={showModal}
      visible={showModal}
      /*transparent /!*={!(isIOS && isFull)}*!/*/
      onSwipeComplete={closeModal}
      presentationStyle={(isIOS || isSmall || isFull) && 'pageSheet'}
      onRequestClose={closeModal}
      onBackdropPress={closeModal}>
      <ScrollView
        contentContainerStyle={[C.justifyCenter]}
        style={[
          C.radius2,
          onTop(),
          C.mxQuarter,
          C.hFull,
          C.flex,
          isSmall ? [percWidth(0.9), C.selfCenter] : C.maxwHalf,
          C.bgWhite,
          /* C.minwHalf,*/
          /*C.flex,
          /!*isFull && percWidth(0.8),
          percHeight(isFull ? 0.9 : isSmall ? 0.95 : 0.5),*!/
          isSmall
            ? [C.ml_5, C.mb1, C.absolute, C.bottom_6, percWidth()]
            : [C.maxw260, C.selfCenter],*/
        ]}>
        <View style={[C.px2, topRadius(8)]}>
          <DgIcon
            name={'close'}
            extraStyle={[
              C.absolute,
              C.right0,
              onTop(),
              C.p1,
              C.pl2,
              C.selfCenter,
              C.m1,
              C.itemsCenter,
              C.justifyCenter,
              C.bgWhite,
              C.radius6,
            ]}
            color={store.colors.primary}
            size={6}
            onPress={closeModal}
          />

          <View
            /*contentContainerStyle={[C.hFull]}*/
            style={[botRadius(8), C.hFull, C.flex, contentStyle]}>
            {children}
          </View>
        </View>
      </ScrollView>
    </Modal>
  ));
};
