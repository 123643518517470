import {Image, TouchableOpacity, View} from 'react-native';
import C, {apply} from 'consistencss';
import {bgColor, bordColor, OPACITY, palette, shadowCol} from '../styles/color';
import {getInitials} from '../utils/text';
import React from 'react';
import {textTypes} from '../styles/typografy';
import {WfText} from '../⚛️ atoms/WfText';
import {vSizes} from '../styles/sizes';
import {useStores} from '../store/UserProvider';

export const sizeIcons = {
  sm: {wrap: [C.h3, C.w3], icon: 'sm', text: textTypes.bodyBold},
  mid: {wrap: [C.h14, C.w14], icon: 'lg', text: textTypes.bodyBold},
  big: {wrap: [C.h20, C.w20], icon: '3xl', text: textTypes.h1},
  xl: {wrap: [C.h30, C.w30], icon: '3xl', text: textTypes.mainTitle},
  xxl: {wrap: [C.h80, C.w80], icon: '3xl', text: textTypes.mainTitle},
};
/*export const WfIcon = ({name, size = sizeIcons.mid.wrap, color}) => {
  //const iconName = typeof name === "string" ? name : Object.entries(WfIcons).filter()
  return <Icon name={name} style={size} color={color} />;
};*/
/*import Ionicons from 'react-native-vector-icons/dist/glyphmaps/Ionicons.json';
export const icons = Object.keys(Ionicons)*/
export const WfAvatar = ({
  color,
  name,
  compact,
  withBg = true,
  onPress,
  wrapStyle,
  image,
  icon,
  showBubble /*_.isEmpty(icon) */ /*&& (image || name)*/,
  size = sizeIcons.mid,
}) => {
  const {shadow, colors} = useStores();

  const currColor = color || colors.primary;
  return (
    <TouchableOpacity
      onPress={onPress}
      underlayColor={palette.paleGrey}
      style={apply(
        !image
          ? [C.p3, size.wrap, bordColor(currColor, 2)]
          : bordColor(palette.white, 2),
        withBg && [
          bgColor(image ? palette.white : currColor + OPACITY),
          shadowCol(colors.primary),
        ],
        C.itemsCenter,
        C.justifyCenter,
        C.radius20,
        wrapStyle,
      )}>
      {image ? (
        <Image
          resizeMode={'contain'}
          source={{uri: image}}
          style={[size.wrap, C.radius20]}
        />
      ) : name ? (
        <WfText type={size.text} style={[C.uppercase]} color={currColor}>
          {getInitials(name)}
        </WfText>
      ) : (
        <WfText type={size.text} color={currColor}>
          {icon}
        </WfText>
      )}
      {showBubble && icon && (
        <View
          style={[
            C.bgWhite,
            shadowCol(currColor),
            C.radius4,
            C.absolute,
            C.itemsCenter,
            C.justifyCenter,
            size.wrap,
            compact
              ? [C.right_3, C.bottom_3, vSizes.icon]
              : [C.right_2, C.bottom_2, vSizes.sm, C.px1],
          ]}>
          <WfText type={[size.text, C.alignCenter]}>{icon}</WfText>
        </View>
      )}
    </TouchableOpacity>
  );
};
