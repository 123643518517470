import {mockUsers} from '../store/AuthStore';
import React, {useState} from 'react';
import {FlatList, Image, Text, TouchableOpacity, View} from 'react-native';
import C from 'consistencss';
import {isSmall, onTop, vSizes} from '../styles/sizes';
import {pillWrapper} from '../styles/ui';
import {bordColor, palette, textColor} from '../styles/color';
import {WfAvatar} from '../🧫 molecules/WfAvatar';
import {wFonts} from '../styles/typografy';

import LinearGradient from 'react-native-linear-gradient';
import {useObserver} from 'mobx-react';
import {useStores} from '../store/UserProvider';
import PaletteSelector from './PaletteSelector';
import {signedIn} from '../routes';
import {useNavigation} from '@react-navigation/core';
import {vImgs} from '../styles/icons';

export const Header = ({users = mockUsers}) => {
  const store = useStores();
  const {navigate} = useNavigation();
  const [showPalette, setShowPalette] = useState();
  /*const {user, colors, mainGrad} = store;*/
  return useObserver(() => (
    <LinearGradient
      colors={store.mainGrad}
      style={[
        /*isIOS && [C.top_12, C.pt12],*/
        C.row,
        {position: 'sticky'},
        C.py4,
        /* C.itemsCenter,*/
        C.justifyCenter,
        /*isSmall ? percWidth(1.1) : C.minwFull,*/
        C.top0,
        onTop(),
      ]}
      useAngle
      angle={120}>
      <View style={[C.justifyCenter, C.flex]}>
        <View
          style={[C.row, C.flex, C.justifyBetween, isSmall ? C.px3 : C.px30]}>
          {/**Logo*/}
          <TouchableOpacity
            style={[C.row, C.itemsCenter]}
            onPress={() => setShowPalette(!showPalette)}>
            <View style={[C.radius4, C.p2, C.bgWhite, C.mr4]}>
              <Image
                source={vImgs.logoWhite}
                resizeMode={'contain'}
                style={[vSizes.lg]}
              />
            </View>
            <Text
              style={[wFonts.mainTitle, textColor(palette.white), C.font15]}>
              dgfolio
            </Text>
          </TouchableOpacity>

          <View style={[C.row, C.itemsCenter]}>
            {/*<DgIcon
            name={'colours'}
            size={10}
            color={palette.white}
            extraStyle={[C.ml4]}
          />*/}
            <FlatList
              data={users}
              horizontal
              keyExtractor={({name}, index) => name + '-' + index}
              renderItem={({item, index, length}) => (
                <TouchableOpacity
                  onPress={() => {
                    navigate(signedIn.Profile.name);
                    store.setUser(item);
                  }}
                  style={[
                    pillWrapper,
                    C.radius20,
                    C.p1,
                    index > 0 && isSmall && C.ml_8,
                    store.user.name === item.name && bordColor(palette.white),
                  ]}>
                  <WfAvatar
                    onPress={() => store.setUser(item)}
                    image={item.image}
                    name={item.name}
                    color={store.colors.primary}
                  />
                  {!isSmall && (
                    <Text
                      style={[
                        C.p2,
                        wFonts.textBold,
                        textColor(
                          store.user.name !== item.name
                            ? palette.gray300
                            : palette.white,
                        ),
                      ]}>
                      {item.name}
                    </Text>
                  )}
                </TouchableOpacity>
              )}
            />
          </View>
        </View>
        {showPalette && <PaletteSelector />}
      </View>
    </LinearGradient>
  ));
};
