import C from 'consistencss';
import {Linking, Text, View} from 'react-native';
import React from 'react';
import {DgIcon} from '../⚛️ atoms/DgIcon';
import {Pressable} from 'react-native-web-hover';

import {bgColor, bordColor, palette, textColor} from '../styles/color';
import {BASE_PIXEL, onTop, vSizes} from '../styles/sizes';
import _ from 'lodash';
import {wFonts} from '../styles/typografy';

export default ({list = [], links}) => {
  /*const {shadow, colors} = useStores();*/

  /*todo add timer:
     useEffect(() => {
    const fetchData = async () => {
      await sleep(1000);
      // this will log 'Hello Word' to the console
      console.log('Hello World');
    };

    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);*/

  /*const currColorBg = colorBg || colors.primaryLight;*/
  /**Todo add chroma for more contrast check?*/

  const actualList = list.filter(
    ({name}, index) => !!links[name] || index % 2 === 0,
  );
  const fragment = (2 * Math.PI) / actualList.length;
  return (
    <View style={[C.top22, C.pb6, C.left6, onTop()]}>
      {actualList.map(({name, iconName}, index) => {
        const angle = fragment * index;
        const pos = (big = false) => ({
          bottom: BASE_PIXEL * (big ? 22 : 10) * Math.cos(angle),
          right: BASE_PIXEL * (big ? 22 : 12) * Math.sin(angle),
        });
        const link = links[name];
        const hasLink = !_.isEmpty(link);
        const color = hasLink ? palette.white : palette.paleGreyThree;
        return (
          <Pressable
            onPress={() => hasLink && Linking.openURL(link)}
            style={({hovered, focused, pressed}) => [
              C.absolute,
              C.m2,
              C.py1,
              C.radius8,
              hovered ? vSizes.med : vSizes.sm,
              C.itemsCenter,
              C.justifyCenter,
              bgColor(palette.whiteTransp),
              hasLink && bordColor(palette.white),
              pos(true),
              C.row,
            ]}>
            {({hovered, focused, pressed}) => (
              <View key={name}>
                <DgIcon
                  name={name}
                  extraStyle={C.px2}
                  size={hovered ? 6 : 4}
                  iconName={iconName}
                  color={color}
                />
                {hovered && (
                  <Text
                    style={[
                      C.capitalize,
                      C.absolute,
                      wFonts.bodySmall,
                      C.left0,
                      index >= actualList.length / 4 &&
                      index <= (3 * actualList.length) / 4
                        ? C.bottom_8
                        : C.top_8,
                      textColor(color),
                      onTop(),
                    ]}>
                    {name}
                  </Text>
                )}
              </View>
            )}
          </Pressable>
        );
      })}
    </View>
  );
};
