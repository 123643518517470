import {FlatList, ImageBackground, Text, View} from 'react-native';
import C from 'consistencss';
import {Pressable} from 'react-native-web-hover';

import {wFonts} from '../styles/typografy';
import React from 'react';
import {textColor, vShadow} from '../styles/color';
import {isSmall, percWidth, respL, topRadius} from '../styles/sizes';
import ProfileRow from '../🧫 molecules/ProfileRow';
import DgTag from '../🧫 molecules/DgTag';
import {useStores} from '../store/UserProvider';
import {useObserver} from 'mobx-react';
import LazyLoad from 'react-lazyload';

export const WfCard = job => {
  const store = useStores();
  const handlePress = () => {
    store.setCurrJob(job);
    store.detailsModal.showModal();
  };
  const {collapsed, description, technologies, image, link} = job;
  return useObserver(() => (
    <Pressable
      style={({hovered, focused, pressed}) => [
        percWidth(isSmall ? 0.9 : respL ? 0.28 : 0.4),
        C.m6,
        C.radius4,
        /*C.borderHairline,
        {borderColor: palette.gray200},*/
        hovered ? store.shadow : vShadow.highlight, //bordColor(palette.gray300),
        C.shadowMd,
      ]}
      onPress={handlePress}>
      {({hovered, focused, pressed}) => (
        <View>
          {/*{link && hovered && (
            <DgIcon
              name={'open'}
              extraStyle={[
                C.absolute,
                C.right0,
                onTop(),
                C.px5,
                C.py2,
                C.bgWhite,
                store.shadow,
                C.radius6,
              ]}
              color={store.colors.primary}
              size={6}
              onPress={() => Linking.openURL(link)}
            />
          )}*/}

          {
            image && (
              <LazyLoad height={200}>
                <ImageBackground
                  source={image}
                  resizeMode={hovered ? 'center' : 'cover'}
                  style={[!collapsed && C.pt60, C.wrap]}
                  imageStyle={[topRadius(4), C.bgWhite]}
                />
              </LazyLoad>
            ) /* : (
            <WebView
              source={{uri: 'https://oralify.com/'}}
              style={[/!*!collapsed && C.pt60, *!/ C.h60]}
            />
          )*/
          }

          <View style={[C.pt2]}>
            <ProfileRow {...job} showImg showDate />
            <Text
              numberOfLines={hovered ? 2 : 1}
              style={[
                wFonts.body1,
                C.p4,
                hovered && C.pb12,
                textColor(),
                C.wFull /*percWidth(0.25)*/,
              ]}>
              {description}
            </Text>

            {/** Skills*/}
            <FlatList
              horizontal
              keyExtractor={({name}) => name}
              contentContainerStyle={[
                C.justifyStart,
                C.itemsCenter,
                C.alignStart,
              ]}
              style={[C.pl4, C.my_2]}
              data={isSmall ? technologies.slice(0, 2) : technologies}
              renderItem={({item}) => (
                <DgTag
                  key={item.name}
                  compact
                  {...item}
                  selected={hovered}
                  highlight
                />
              )}
            />
          </View>
        </View>
      )}
    </Pressable>
  ));
};
