import {Text} from 'react-native';
import {textTypes} from '../styles/typografy';
import React from 'react';
import {apply} from 'consistencss';
import {textColor} from '../styles/color';

export const WfText = ({children, color, type = textTypes.regular}) => {
  return (
    <Text
      style={apply(type.style, textColor(color) /*{fontFamily: type.family}*/)}>
      {children}
    </Text>
  );
};
