import {palette} from './color';
import C, {extend, responsive} from 'consistencss';
import {BASE_PIXEL, DESKTOP, DESKTOP_HUGE, isIOS, isWeb, TABLET} from './sizes';
import {Platform} from 'react-native';

export const uiKit = () =>
  extend({
    sizing: {
      base: BASE_PIXEL,
    },
    layout: {
      xs: 0,
      sm: 411,
      md: 568,
      lg: TABLET,
      xl: DESKTOP,
      xxl: DESKTOP_HUGE,
    },
    colors: palette,
  });

export const pillWrapper = [
  C.mr3,
  C.mb3,
  C.itemsCenter,
  C.p2,
  C.row,
  /*bgColor(palette.whiteTransp),*/
];

export const bubblePos = [
  C.absolute,
  C.justifyEnd,
  isWeb &&
    responsive({
      sm: C.bottom5,
      md: [C.bottom36, C.left2],
      l: [C.bottom6, C.left1],
      xl: [C.bottom4, C.left2],
    }),
];

export const fullAbs = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const showReverse = {flexDirection: 'row-reverse'};
export const absCenter = [
  C.absolute,
  isIOS && C.mt8,
  C.itemsCenter,
  C.top0,
  C.selfCenter,
];

export const sounds = {
  bg: 'http://commondatastorage.googleapis.com/codeskulptor-demos/pyman_assets/ateapill.ogg',
  match:
    'http://codeskulptor-demos.commondatastorage.googleapis.com/descent/gotitem.mp3',
  combo:
    'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-bounce.m4a',
  combo2:
    'http://commondatastorage.googleapis.com/codeskulptor-assets/week7-brrring.m4a',
  wrong: 'https://rpg.hamsterrepublic.com/wiki-images/d/d7/Oddbounce.ogg',
  sword: 'https://freesound.org/people/FunWithSound/sounds/361485/',
  glass:
    'http://www.superluigibros.com/downloads/sounds/SNES/SMRPG/wav/smrpg_battle_vigorup.wav',
  level: 'https://www.fesliyanstudios.com/play-mp3/6599',
};

/**Hover*/
export const hover = {
  buttonHovered: {
    backgroundColor: palette.blue + '40',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  buttonFocused: {
    backgroundColor: palette.blue + '50',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
  },
  buttonPressed: {
    backgroundColor: palette.blue + '70',
    shadowColor: palette.blue,
    shadowOffset: {
      width: 0,
      height: 9,
    },
    shadowOpacity: 0.5,
    shadowRadius: 12.35,
    elevation: 19,
  },
};
