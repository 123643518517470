import C from 'consistencss';
import {Dimensions, Platform} from 'react-native';
import {isTablet} from 'react-native-device-info';

export const BASE_PIXEL = 4;

export const MOBILE = 375;
export const TABLET = 768;
export const DESKTOP = 1024;
export const DESKTOP_HUGE = 1440;

/**xs: 0
 sm: 411
 md: 568
 lg: 768
 xl: 1024
 xxl: 1280*/

export const deviceHeight = Dimensions.get('window').height;
export const deviceWidth = Dimensions.get('window').width;

export const maxWidth = (perc = 1) => ({maxWidth: deviceWidth * perc});
export const percWidth = (perc = 1) => ({width: deviceWidth * perc});
export const percHeight = (perc = 1) => ({height: deviceHeight * perc});

/** Radius */

export const topRadius = (rad = 5) => ({
  borderTopLeftRadius: rad * BASE_PIXEL,
  borderTopRightRadius: rad * BASE_PIXEL,
});
export const LeftRadius = (rad = 5) => ({
  borderTopLeftRadius: rad * BASE_PIXEL,
  borderBottomLeftRadius: rad * BASE_PIXEL,
});
export const RightRadius = (rad = 5) => ({
  borderTopRightRadius: rad * BASE_PIXEL,
  borderBottomRightRadius: rad * BASE_PIXEL,
});
export const onTop = (over = true) => ({
  zIndex: BASE_PIXEL * (over ? 40 : -40),
});
export const botRadius = (rad = 5) => ({
  borderBottomLeftRadius: rad * BASE_PIXEL,
  borderBottomRightRadius: rad * BASE_PIXEL,
});
export const leftRadius = (rad = 5) => ({
  borderBottomLeftRadius: rad * BASE_PIXEL,
  borderTopStartRadius: rad * BASE_PIXEL,
});

/**Responsiveness*/
export const isIOS = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const isTabl = isTablet();

export const respL = isWeb && deviceWidth >= DESKTOP_HUGE; //Gegant => container 1200 (300)
export const respM =
  isWeb && deviceWidth > DESKTOP && deviceWidth < DESKTOP_HUGE; // Margin 32 (8)
export const respS = isWeb && deviceWidth > TABLET && deviceWidth < DESKTOP; //Tablet   => Margin 28 (7)
export const respXS = isWeb && deviceWidth < TABLET && deviceWidth > MOBILE; //Tablet   => Margin 28 (7)
export const respMob = isWeb && deviceWidth < MOBILE; //Mobile M  // Margin 24 (6)
export const isWide = isTabl || (isWeb && deviceWidth > 450);

export const isSmall = isWeb && deviceWidth < TABLET;
/** @deprecated
export const isBig = isTabl; //|| (isWeb && deviceWidth > 450);
export const isLarge = isTabl || (isWeb && deviceWidth > 600);
export const isWide = isTabl || (isWeb && deviceWidth > 450);
export const isNarrow = isWeb && deviceWidth < 380;
export const isPhoneBrowser = isWeb && deviceWidth < 480;*/

/**Most important wrapper container*/
export const respWrap = [
  percWidth(),
  respL ? C.w340 : respM ? C.px8 : respS ? C.px7 : C.px6,
  isSmall && [percWidth(), C.px6],
  C.justifyCenter,
  /*{width: deviceWidth * (isWide ? 0.7 : 0.8)]}*/
];

/**Most important container*/
export const respWidth = [respS ? C.maxw180 : maxWidth(0.7)];

export const vSizes = {
  dot: [C.w2, C.h2],
  bubble: [C.w3, C.h3],
  box: [C.w5, C.h5],
  icon: [C.w6, C.h6],
  emoji: [C.w7, C.h7],
  sm: [C.w8, C.h8],
  input: [C.w10, C.h10],
  cardSm: [C.w40, C.h40],
  cardMd: [C.w60, C.h60],
  card: [C.w80, C.h80],
  cardBig: [C.w92, C.h66],
  cardHuge: [C.w120, C.h120],
  cardHero: [C.w160, C.h190],
  med: [C.w12, C.h12],
  lg: [C.w15, C.h15],
  xl: [C.w20, C.h20],
  emojiSm: C.font5,
  emojiLg: C.font7,
  logo: isWide ? [C.w37, C.h37] : [C.w20, C.h20],
};
