import React from 'react';
import {FlatList, Image, Text, View} from 'react-native';
import C from 'consistencss';

import {
  BASE_PIXEL,
  deviceWidth,
  isSmall,
  onTop,
  percWidth,
  respL,
} from '../styles/sizes';
import {Pressable} from 'react-native-web-hover';
import {sizeIcons, WfAvatar} from './WfAvatar';
import {bordColor, palette, shadowCol, textColor} from '../styles/color';
import {showReverse} from '../styles/ui';
import {wFonts} from '../styles/typografy';
import {dateSummary, toDate} from '../utils/date';
import LinearGradient from 'react-native-linear-gradient';
import DgTag from './DgTag';
import {useStores} from '../store/UserProvider';

import {useObserver} from 'mobx-react';
import {DgIcon} from '../⚛️ atoms/DgIcon';

export const SideCard = job => {
  const store = useStores();
  /*const {mainGrad, secGrad, colors, shadow} = store;*/
  const {
    icon,
    logo,
    image,
    start,
    type,
    end,
    left,
    description,
    name,
    link,
    technologies,
    position,
  } = job;
  const respToLeft = !isSmall && left;
  const tagRadius = [
    C.px3,
    /*C.radius6,*/
    /*darkShadow.shadow,*/
    !left ? C.radiusright2 : C.radiusleft2,
  ];
  const mainColor = !type ? store.colors.secondary : store.colors.primary;
  const handlePress = () => {
    store.setCurrJob(job);
    store.detailsModal.showModal();
  };

  return useObserver(() => (
    <View
      style={[
        C.p4,
        !isSmall
          ? [C.row, percWidth(0.8), left && showReverse]
          : [C.selfCenter, percWidth(1)],
      ]}>
      {/**Dates*/}
      <View
        style={
          isSmall
            ? [C.absolute, C.selfCenter, left ? C.left10 : C.right10]
            : [
                C.flex,
                C.py4,
                left ? C.itemsStart : C.itemsEnd,
                C.justifyBetween,
                C.mt18,
                left ? C.left_9 : C.right_9,
              ]
        }>
        <Text
          numberOfLines={2}
          style={[
            /*tagRadius,*/
            left ? C.radiusright6 : C.radiusleft6,
            //hovershadowCol(mainColor),
            bordColor(mainColor),
            C.py1,
            C.px2,
            wFonts.body1,
            C.alignRight,
            textColor(mainColor),
          ]}>
          {/*{left && '⊙ '}*/} {end ? dateSummary(end) : 'now'} {!left && ' ⊙'}
        </Text>
        {/** Duration*/}
        <Text
          numberOfLines={2}
          style={[
            C.radius8,
            /*bordColor(palette.turquoise),*/
            C.p1,
            C.px2,
            wFonts.body1,
            C.alignRight,
            textColor(palette.gray600),
          ]}>
          {toDate(start, end)}
        </Text>
        <Text
          numberOfLines={2}
          style={[
            left ? C.radiusright6 : C.radiusleft6,
            /*darkShadow.shadow,*/
            bordColor(palette.blueDark),
            C.py1,
            C.px2,
            wFonts.body1,
            C.alignRight,
            textColor(palette.blueDark),
          ]}>
          {/*{left && '⊙ '}*/} {dateSummary(start)} {!left && ' ⊙'}
        </Text>
      </View>
      {/**Center*/}
      <LinearGradient
        colors={[mainColor, palette.purple]}
        style={[
          C.absolute,
          C.radius10,
          {left: deviceWidth * (isSmall ? 0.5 : 0.4) - BASE_PIXEL},
          isSmall ? [C.top_4] : C.top10,
          C.w2,
          C.hFull,
          onTop(false),
        ]}
      />
      <View style={[C.itemsCenter, C.mb10]}>
        <Pressable style={[C.bgWhite]} onPress={handlePress}>
          {({hovered, focused, pressed}) => (
            <WfAvatar
              image={logo}
              name={name}
              size={sizeIcons.big}
              showBubble={hovered}
              icon={icon}
            />
          )}
        </Pressable>
      </View>
      {/**Card*/}
      <Pressable
        style={[C.flex, onTop()]}
        onPress={handlePress /*() => Linking.openURL(link)*/}>
        {({hovered, focused, pressed}) => (
          <View
            style={[
              !isSmall && (!left ? C.ml4 : C.mr4),
              /*C.m4,*/
              C.bgWhite,
              C.radius4,
              hovered ? shadowCol(mainColor) : bordColor(palette.gray200),
              C.shadowMd,
              respToLeft && C.row,
              !left && showReverse,
            ]}>
            {respL && image && (
              /*<LazyLoad height={200}>*/
              <Image
                source={{uri: image}}
                /*resizeMode={hovered ? 'center' : 'cover'}*/
                style={[
                  C.flex,
                  C.bgWhite,
                  /*left ? C.radiusleft4 : C.radiusright4,*/ tagRadius,
                ]}
              />
              /* </LazyLoad>*/
            )}
            {/*<ProfileRow {...user} />*/}
            <View style={[C.flex, C.p4]}>
              <Text style={[wFonts.mainTitle, textColor()]}>{name}</Text>
              <View style={[C.row, C.itemsCenter, C.justifyStart]}>
                <DgIcon
                  name={type ? 'graduation-cap' : 'suitcase'}
                  color={mainColor}
                  size={6}
                />
                <Text style={[wFonts.textBold, C.my3, textColor(mainColor)]}>
                  {position}
                </Text>
              </View>
              {description && (
                <Text
                  numberOfLines={hovered ? 5 : 2}
                  style={[wFonts.body1, textColor()]}>
                  {description}
                </Text>
              )}
              {/** Skills*/}
              <FlatList
                /*numColumns={2}*/
                showsHorizontalScrollIndicator={false}
                horizontal
                keyExtractor={({key}) => key}
                style={[C.mt4]}
                contentContainerStyle={isSmall && C.maxwHalf}
                data={isSmall ? technologies.slice(0, 2) : technologies}
                renderItem={({item}) => (
                  <DgTag
                    key={item.name}
                    compact
                    {...item}
                    selected={hovered}
                    highlight
                  />
                )}
              />
            </View>
          </View>
        )}
      </Pressable>
    </View>
  ));
};
