import React, {createContext, useContext} from 'react';
import {createProfileStore} from './UserStore';
import {useLocalStore} from 'mobx-react';

export const StoreContext = createContext();

export const useStores = () => useContext(StoreContext);

/*export const useRootStore = render => {
  return useObserver(() => {
    const store = useContext(StoreContext);
    return render(store);
  });
};*/

export const StoreProvider = ({children}) => {
  const userStore = useLocalStore(createProfileStore);
  return (
    <StoreContext.Provider value={userStore}>{children}</StoreContext.Provider>
  );
};
