export const transKeysSp = {
  heroTitle: 'Crea tu portafolio en cuestión de minutos',
  heroSubitle: 'Inspírate entre cientos de profesionales cerca de ti',
};

export const defLanguage = 'es-Es';
export const languages = {
  sp: {
    code: defLanguage,
    icon: '🇪🇸',
    name: 'Español',
    translations: transKeysSp,
  },
  en: {code: 'en-US', icon: '🇺🇸', name: 'English', translations: transKeysSp},
};
export const trans = (key, params, lang = languages.sp) =>
  lang.translations[key] || key;
