import C from 'consistencss';
import {onTop, vSizes} from '../styles/sizes';
import {FlatList, TouchableOpacity, View} from 'react-native';
import React, {useState} from 'react';
import {useStores} from '../store/UserProvider';
import chroma from 'chroma-js';
import Pill from '../🧫 molecules/Pill';
import {useObserver} from 'mobx-react';
import {bgColor, palette} from '../styles/color';

export default () => {
  const store = useStores();
  const [showWheel, toggleWheel] = useState();
  return useObserver(() => (
    <View style={[C.flex, C.itemsCenter, C.py2]}>
      <View style={[C.row, C.itemsCenter]}>
        <FlatList
          /*numColumns={Math.floor((deviceWidth * 0.8) / 150)}*/
          keyExtractor={({name}) => name}
          horizontal
          contentContainerStyle={[C.selfStart, onTop(), C.my8]}
          data={Object.entries(store.palette)}
          renderItem={({item}) => {
            const [key, currentPalette] = item;
            const isSelected = key === store.paletteKey;
            return (
              <Pill selected={isSelected} onPress={() => store.setPalette(key)}>
                {Object.values(currentPalette).map((currColor, index) => (
                  <View /*style={[index > 0 && C.ml_3]}*/>
                    <View
                      /*name={'circle-full'}*/
                      /*size={6}*/
                      style={[
                        C.radius6,
                        vSizes.box,
                        C.radius5,
                        index > 0 && C.ml_1,
                        bgColor(currColor),
                      ]}
                      /*color={currColor}*/
                    />
                  </View>
                ))}
              </Pill>
            );
          }}
        />
        <Pill
          iconName={'plus'}
          selected
          transp
          onPress={() => toggleWheel(!showWheel)}
        />
      </View>
      {showWheel && (
        <View style={[C.row, C.m2, bgColor(palette.white), C.radius6, C.p1]}>
          {[...store.availPalette, chroma.random().hex()].map(col => (
            <TouchableOpacity
              style={[C.radius6, vSizes.sm, bgColor(col), C.ml_2]}
              onPress={() => {
                store.addColor(col);
                toggleWheel();
              }}
            />
          ))}
          {/*<ChromePicker
            color={store.palette.primary}
            onChange={color => {
              setColor(color.hex);
              store.addColor(color.hex);
              toggleWheel();
            }}
          />*/}
        </View>
      )}
    </View>
  ));
};
