// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics, logEvent} from 'firebase/analytics';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import {USERS} from './UserStore';

/*import {getAuth} from 'firebase/auth';*/

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAFzSgqpBzgKR65GPzvbgZpR8mSSD5rZrw',
  authDomain: 'dgfolio.firebaseapp.com',
  projectId: 'dgfolio',
  storageBucket: 'dgfolio.appspot.com',
  messagingSenderId: '199449331320',
  appId: '1:199449331320:web:08630aa1dac038f09c0f8d',
  measurementId: 'G-8FW5YXHJJ7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/**Analytics*/
const analytics = getAnalytics(app);

export const trackEvent = (name, params) => logEvent(analytics, name, params);

/** DB CRUD*/
export const db = getFirestore(app);

export const getAllDB = async (path = USERS) => {
  const querySnapshot = await getDocs(collection(db, path));
  querySnapshot.forEach(currDoc => {
    // doc.data() is never undefined for query doc snapshots
    console.log(currDoc.id, ' => ', currDoc.data());
    return currDoc.data();
  });
};

export const getDB = async (path = USERS, subset) => {
  const docRef = doc(db, path, subset);
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', path, subset, docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  } catch (e) {
    console.log('Error getting cached document:', e);
  }
};

/**Real Time*/
/*export const unsubscribeData = onSnapshot(doc(db, 'cities', 'SF'), doc => {
  console.log('Current data: ', doc.data());
});*/
export const setDocDB = async (data, path = USERS, subset) => {
  await setDoc(doc(db, path, subset), data);
};
export const addDB = async (data, path = USERS) => {
  try {
    const docRef = await addDoc(collection(db, path), data);
    console.log(
      'Document ' + data + ' written ' + path + ' with ID: ',
      docRef.id,
    );
    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

/**Auth*/
/*const auth = getAuth(app);*/

/*export const login = () =>
  onAuthStateChanged(auth, user => {
    // Check for user status
  });*/
