import {FlatList, Text, View} from 'react-native';
import C from 'consistencss';
import React, {useEffect, useState} from 'react';
import {Wrapper} from '../comp/Wrapper';
import {Timeline} from '../\uD83E\uDDECorganisms/Timeline';
import Pill from '../🧫 molecules/Pill';
import {useStores} from '../store/UserProvider';

import ProfileHeader from '../comp/ProfileHeader';
import {useObserver} from 'mobx-react';
import {palette, textColor} from '../styles/color';
import {wFonts} from '../styles/typografy';
import {isSmall} from '../styles/sizes';
import {Header} from '../🧬organisms/Header';

export const Profile = () => {
  const store = useStores();
  const [showFilter, setShowFilters] = useState(false);
  const numColumns = isSmall ? 2 : 4;
  const nDisplay = numColumns * 2 - 1;
  const filtersToShow =
    /*showFilter
    ?*/ store.filterDurations;
  /*: store.filterDurations.slice(0, nDisplay);*/
  const missing = store.matchingSkills.length - filtersToShow.length;
  const expandIcon = {
    iconName: showFilter ? 'chevron-up' : 'chevron-down',
    name: !showFilter ? missing : filtersToShow.length,
  };

  useEffect(() => {
    const fetchData = async () => {
      await store.initDB();
    };
    fetchData().catch(console.error);
  }, [store]);
  return useObserver(() => (
    <>
      <Header />
      <Wrapper
        bgGradient={store.mainGrad}
        HeaderChild={
          <View
            style={[
              /*C.mlQuarter,*/
              C.itemsCenter,
              C.selfCenter,
              C.top_2,
              isSmall ? [C.left2, C.top4, C.wFull] : C.row,
              /*C.mb_16,*/
              C.justifyCenter,
            ]}>
            <View>
              <ProfileHeader />

              {/**Oralify*/}
              {/** Filters */}
              <FlatList
                data={filtersToShow}
                numColumns={numColumns}
                style={[C.my4]}
                extraData={store.matchingSkills}
                contentContainerStyle={[C.itemsCenter, C.justifyCenter]}
                /*ListFooterComponent={() => (
                  <Pill
                    iconName={expandIcon.iconName}
                    name={expandIcon.name}
                    number={!showFilter ? 'more' : ''}
                    selected
                    color={palette.white}
                    onPress={() => setShowFilters(!showFilter)}
                  />
                )}*/
                keyExtractor={({name}, index) => name + '-' + index}
                renderItem={({item, index, length}) => {
                  const {key, name, marked, duration, formattedDuration} = item;
                  /*const matchingResults = store.user.highlights.filter(
                  ({technologies}) =>
                    technologies && flattenByKey(technologies).includes(name),
                ).length;*/
                  const isLast = index === filtersToShow.length;
                  const onToggle = () =>
                    /*isLast
                      ? setShowFilters(!showFilter)
                      : */ store.toggleCurrFilter(key);
                  return (
                    duration > 0 && (
                      <Pill
                        transp
                        {...item}
                        onPress={onToggle}
                        compact={false}
                        highlight
                        selected={store.hasMatchingFilters ? !!marked : true}
                        number={!isLast ? formattedDuration : ''}
                      />
                    )
                  );
                }}
              />
              <Text
                style={[
                  wFonts.bodySmallReal,
                  C.selfCenter,
                  C.itemsCenter,
                  C.mb6,
                  textColor(palette.white),
                ]}>
                Total Exp: {'  '}
                <Text
                  style={[
                    wFonts.bodySmall,
                    C.alignCenter,
                    C.mb6,
                    textColor(palette.white),
                  ]}>
                  {store.totalDuration}
                </Text>
                {' (' +
                  new Intl.NumberFormat('es-ES', {
                    maximumSignificantDigits: 3,
                  }).format(store.totalWorkedHours)}{' '}
                h)
              </Text>
            </View>

            {/** Oralify iFrame CTA*/}
            <View
              style={[
                C.flex,
                isSmall
                  ? [
                      C.ml6,
                      C.top105,
                      {
                        transform: [{scaleX: 0.7}, {scaleY: 0.7}],
                      },
                      C.absolute,
                    ]
                  : C.ml10,
                C.selfCenter,
              ]}>
              <iframe
                height={500}
                width={600}
                frameBorder={0}
                /*style={{backgroundColor: '#fff'}}*/
                src={
                  'https://oralify.com/embed/guillermoS?transparent=1' /*+
                  (isSmall ? '/qr' : '')*/
                }
              />
            </View>
            {isSmall && <View style={[C.h90, C.bgRed]} />}
          </View>
        }>
        <Timeline />
        <View style={[C.p20]} />
      </Wrapper>
    </>
  ));
};
