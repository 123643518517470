import Icon from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon3 from 'react-native-vector-icons/Ionicons';
import Icon4 from 'react-native-vector-icons/AntDesign';
import Icon5 from 'react-native-vector-icons/Octicons';
import Icon6 from 'react-native-vector-icons/Fontisto';
import Icon7 from 'react-native-vector-icons/Entypo';
import {BASE_PIXEL} from '../styles/sizes';
import C from 'consistencss';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';

const availIcons = [Icon3, Icon7, Icon, Icon2, Icon4, Icon5, Icon6];

const checkIcon = (Comp, name) =>
  Comp.hasIcon(name.toLowerCase()) ? {CurrIcon: Comp, currLogo: name} : {};

export const DgIcon = ({
  name,
  iconName,
  color,
  onPress,
  size = 4,
  extraStyle,
  priority = 0,
}) => {
  const Wrapper = onPress ? TouchableOpacity : View;
  const existing = availIcons
    .map(CurrIcon => checkIcon(CurrIcon, iconName || name.toLowerCase()))
    .filter(({currLogo}) => !!currLogo);
  if (existing.length) {
    const {currLogo, CurrIcon} = existing[priority] || existing[0];
    return (
      <Wrapper onPress={onPress} style={[C.pr2, extraStyle]}>
        <CurrIcon
          name={currLogo}
          onPress={onPress}
          size={BASE_PIXEL * size}
          color={color}
        />
      </Wrapper>
    );
  } else {
    return <View />;
  }
};
