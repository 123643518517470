import {DgModal} from '../🧬organisms/Modal';
import {useObserver} from 'mobx-react';
import {useStores} from '../store/UserProvider';
import ProfileRow from '../🧫 molecules/ProfileRow';
import React from 'react';
import {Image, Linking, Text, View} from 'react-native';
import C from 'consistencss';
import {percHeight, topRadius} from '../styles/sizes';
import {wFonts} from '../styles/typografy';
import {textColor} from '../styles/color';
import DgTag from '../🧫 molecules/DgTag';
import {VButton} from '../comp/Wrapper';
import {DgIcon} from '../⚛️ atoms/DgIcon';

export const JobDetailsModal = ({modal}) => {
  const store = useStores();
  const {name, description, technologies, image, link} = store.currentJob;

  const openWeb = () => Linking.openURL(link);
  return useObserver(() => (
    <DgModal modal={modal} /*isFull*/ title={name}>
      <View style={[C.wFull, C.hFull]}>
        {image && (
          <View style={[C.m_2, C.mt_3, C.mb4]}>
            {/*<LazyLoad height={200}>*/}
            <Image
              source={image}
              resizeMode={/*hovered ? 'center' :*/ 'cover'}
              /*style={[C.h80, C.wFull, C.flex]}*/
              style={[topRadius(4), percHeight(0.4), {aspectRatio: 0.8}]}
            />
            {/*</LazyLoad>*/}
          </View>
        )}

        <View style={[C.px10, C.justifyCenter]}>
          <View style={[C.flex]}>
            <ProfileRow {...store.currentJob} showImg showDate>
              {link && (
                <DgIcon
                  size={6}
                  name={'web'}
                  color={store.colors.primary}
                  onPress={openWeb}
                />
              )}
            </ProfileRow>

            <Text
              style={[
                wFonts.body1,
                C.p4,
                textColor(),
                C.wFull /*percWidth(0.25)*/,
              ]}>
              {description}
            </Text>
            {technologies && (
              <View style={[C.row, C.pl4, C.maxwHalf]}>
                {technologies.map(curr => (
                  <DgTag
                    key={curr.name}
                    compact
                    {...curr}
                    /*selected={hovered}*/
                    highlight
                  />
                ))}
              </View>
            )}
          </View>
        </View>
        <VButton title={'More details'} onPress={openWeb} icon={'web'} />
      </View>
    </DgModal>
  ));
};
