import {mockUsers} from './AuthStore';
import {
  customPalettes,
  defPaletteKey,
  palette,
  shadowCol,
} from '../styles/color';
import {makeAutoObservable} from 'mobx';
import ShortUniqueId from 'short-unique-id';
import chroma from 'chroma-js';
import {flattenByKey, matchingKeyArrays, toggleObjKey} from '../utils/text';
import {keywords} from '../mock/guille';
import _ from 'lodash';
import {getComplementaryColor} from '../utils/date';
import {getDB, trackEvent} from './firebase';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const uid = new ShortUniqueId({length: 10});

export const KEYWORDS = 'keywords';
export const SOCIAL = 'social';
export const TECHNOLOGIES = 'technologies';
export const USERS = 'users';

export const createModalStore = (show = false) => {
  const modalStore = makeAutoObservable({
    show: show,
    isMain: false,
    showModal() {
      modalStore.show = true;
    },
    closeModal() {
      modalStore.show = false;
    },
    toggleModal() {
      modalStore.show = !modalStore.show;
    },
  });
  return modalStore;
};

export const createProfileStore = () => {
  const store = makeAutoObservable({
    user: mockUsers[0],
    setUser(newUser) {
      this.user = {...newUser};
      trackEvent(USERS + '-' + newUser.name, {user: newUser});
      this.currFilters = {};
    },
    detailsModal: createModalStore(),
    currentJob: mockUsers[0].highlights[0],
    setCurrJob(currJob) {
      this.currentJob = currJob;
      trackEvent('job-' + currJob.name, {currentJob: this.currentJob});
    },
    currFilters: {},
    social: {},

    async initDB() {
      store.social = await getDB(KEYWORDS, SOCIAL);
      store.currFilters = await getDB(KEYWORDS, TECHNOLOGIES);
      /*updateUserHighlights();*/
    },

    toggleCurrFilter(key) {
      store.currFilters = toggleObjKey(store.currFilters, key);
      trackEvent('filter-' + key, {currentFilter: store.currFilters});
    },

    // Selector para obtener los filtros que tienen coincidencias en las habilidades del usuario
    get matchingSkills() {
      return _.filter(this.currFilters, ({key}) =>
        this.user.highlights.some(({technologies}) =>
          technologies?.map(({key}) => key).includes(key),
        ),
      ).sort();
    },

    // Selector para obtener los filtros marcados
    get matchingFilters() {
      return _.filter(this.currFilters, ({marked}) => !!marked);
    },

    // Selector para verificar si hay filtros coincidentes marcados
    get hasMatchingFilters() {
      return !_.isEmpty(this.matchingFilters);
    },

    // Selector para obtener los aspectos destacados actuales (limitados a 6)
    get currentHighlights() {
      return this.matchingList.filter(({highlight}) => !!highlight);
      //.slice(0, 6);
    },

    // Selector para obtener los aspectos destacados que coinciden con los filtros
    get matchingList() {
      if (_.isEmpty(this.matchingFilters)) {
        return this.user.highlights;
      }

      return this.user.highlights.filter(
        ({technologies}) =>
          technologies &&
          matchingKeyArrays(
            flattenByKey(technologies),
            flattenByKey(this.matchingFilters),
          ),
      );
    },

    // Selector para calcular la duración de cada tecnología en los filtros
    get filterDurations() {
      const currentDate = dayjs();
      const durationFormat = 'Y[y] M[mo]';

      return store.matchingSkills
        .map(filter => {
          const matchingResults = this.user.highlights.filter(
            ({technologies}) =>
              technologies &&
              technologies.map(({key}) => key).includes(filter.key),
          );

          const totalDuration = matchingResults.reduce(
            (total, {start, end}) => {
              const startDate = dayjs(start, 'DD-MM-YY');
              const endDate = end ? dayjs(end, 'DD-MM-YY') : currentDate;
              if (!endDate.isValid()) {
                return total;
              }

              const diffInMonths = endDate.diff(startDate, 'month');

              return diffInMonths > 0 ? total + diffInMonths : total;
            },
            0,
          );

          const formattedDuration = dayjs
            .duration(totalDuration, 'months')
            .format(
              totalDuration === 12
                ? '1[y]'
                : totalDuration > 12
                ? durationFormat
                : 'M[mo]',
            );

          return {...filter, duration: totalDuration, formattedDuration};
        })
        .sort((a, b) => b.duration - a.duration);
    },

    // Selector para calcular la duración total en días/años trabajados
    get totalDuration() {
      const currentDate = dayjs();
      const totalDays = this.user.highlights.reduce(
        (total, {start, end, name}) => {
          const startDate = dayjs(start, 'DD-MM-YY');
          const endDate = end ? dayjs(end, 'DD-MM-YY') : currentDate;
          if (!endDate.isValid()) {
            return total;
          }

          const diffInDays = endDate.diff(startDate, 'day');

          return diffInDays > 0 ? total + diffInDays : total;
        },
        0,
      );

      const currDuration = dayjs.duration(totalDays, 'day');
      return currDuration.format('Y [years] MM [months]');
    },

    get totalWorkedHours() {
      const currentDate = dayjs();
      const workingHoursPerDay = 8;
      const workingDaysPerYear = 280;

      const totalWorkedDays = this.user.highlights.reduce(
        (total, {start, end, name}) => {
          const startDate = dayjs(start, 'DD-MM-YY');
          const endDate = end ? dayjs(end, 'DD-MM-YY') : currentDate;

          if (!endDate.isValid()) {
            return total;
          }

          const diffInDays = endDate.diff(startDate, 'day');
          // Calcula los días laborables asumiendo 5 días laborables por semana
          const diffInWorkingDays = Math.floor(diffInDays * (5 / 7));

          return diffInWorkingDays > 0 ? total + diffInWorkingDays : total;
        },
        0,
      );

      const totalWorkedHours = totalWorkedDays * workingHoursPerDay;

      return totalWorkedHours;
    },

    /** Styles and Color palette*/
    paletteKey: defPaletteKey,
    palette: customPalettes,
    availPalette: chroma
      .scale([palette.blue, palette.turquoise, palette.purple, palette.yellow])
      .mode('hsl')
      .colors(16),

    setPalette(key) {
      this.paletteKey = key;
      trackEvent('');
    },

    addColor(newCol) {
      const key = uid();
      const secondary = getComplementaryColor(newCol);
      this.palette = {
        ...this.palette,
        [key]: {
          primaryLight: chroma(newCol).brighten(),
          primary: newCol,
          primaryDark: chroma(newCol).darken(),

          secondary: secondary,
          secondaryLight: chroma(secondary).brighten(),
        },
      };
      this.setPalette(key);
    },
    get colors() {
      return this.palette[this.paletteKey];
    },
    get colorsList() {
      return Object.values(this.colors);
    },
    get mainGrad() {
      return [this.colors.primary, this.colors.secondary];
    },
    get secGrad() {
      return [this.colors.secondary, palette.turquoise];
    },
    get shadow() {
      return shadowCol(this.colors.primary);
    },
    get shadowSec() {
      return shadowCol(this.colors.secondary);
    },
  });
  return store;
};
