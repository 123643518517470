import React from 'react';
import {View} from 'react-native';
import C, {extend} from 'consistencss';
import {palette} from './styles/color';
import {BASE_PIXEL} from './styles/sizes';
import {StoreProvider} from './store/UserProvider';
import {useObserver} from 'mobx-react';
import {DgNavigator} from './routes';

extend({
  sizing: {
    base: BASE_PIXEL,
  },
  colors: palette /*{/!*...palette, ...profile.colors.palette*!/}*/,
});

const App = () => {
  return useObserver(() => (
    <StoreProvider>
      <View style={[C.hFull, C.wFull]}>
        <DgNavigator>
          {/* <Header />
          <Profile />*/}
          {/*<AppsofactoLanding
        user={currentUser}
        users={users}
        setCurrent={setCurrentUser}
      />*/}
        </DgNavigator>
      </View>
    </StoreProvider>
  ));
};

export default App;
