import C from 'consistencss';
import {WfAvatar} from './WfAvatar';
import {Text, View} from 'react-native';
import {wFonts} from '../styles/typografy';
import {palette, textColor} from '../styles/color';
import {dateSummary, toDate} from '../utils/date';
import React from 'react';
import {isSmall} from '../styles/sizes';
import {useStores} from '../store/UserProvider';
import {useObserver} from 'mobx-react';

export default ({
  logo,
  name,
  hovered,
  location,
  start,
  emoji,
  end,
  icon,
  position,
  showImg,
  showDate,
  children,
}) => {
  const store = useStores();

  return useObserver(() => (
    <View style={[C.flex, C.m4, C.row, C.itemsCenter]}>
      {showImg && <WfAvatar image={logo} name={name} icon={hovered && icon} />}
      {/**Position and company*/}
      <View style={[C.px4, C.itemsStart, C.flex]}>
        <Text numberOfLines={1} style={[wFonts.mainTitle, textColor()]}>
          {name}
        </Text>
        <Text
          numberOfLines={1}
          style={[wFonts.textBold, textColor(store.colors.primary)]}>
          {position}
        </Text>
      </View>
      {!isSmall && children}
      {/**Location & Duration*/}
      {!isSmall && showDate && (
        <View style={[C.px4, C.itemsEnd, C.justifyEnd, /*C.maxw40,*/ C.wrap]}>
          {start && (
            <Text
              numberOfLines={1}
              style={[wFonts.body1, textColor(palette.blueDark)]}>
              {dateSummary(start)} - {end ? dateSummary(end) : 'now'}
            </Text>
          )}

          <Text
            numberOfLines={1}
            style={[wFonts.body1, textColor(palette.gray600)]}>
            ({toDate(start, end)}) {icon}
          </Text>
          {location && (
            <Text
              numberOfLines={1}
              style={[
                wFonts.textBold,
                C.textAlignRight,
                textColor(store.colors.primary),
              ]}>
              {location} {/*📍*/}
            </Text>
          )}
        </View>
      )}
    </View>
  ));
};
