export const getInitials = name => {
  const words = name?.split(' ');
  return words
    .map(word => word[0])
    .join('')
    .slice(0, 2)
    .toUpperCase();
};

export const toggleObjKey = (obj, key) => {
  obj[key].marked = !obj[key].marked;
  return {...obj};
};

export const flattenByKey = (list, key = 'name') => list.map(item => item[key]);
export const matchingKeyArrays = (arr1, arr2) =>
  arr1.some(r => arr2.includes(r));
